// @flow

import React from "react";
import {
    DefaultLayout,
    Layout,
    withPrivateRoute,
    AdminReasons,
} from "@containers";

/**
 * Redenen & subredenen
 */
const Redenen = (props: *) => (
    <Layout {...props}>
        <DefaultLayout title="Redenen & subredenen">
            <AdminReasons id="beheer-redenen" />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(Redenen);
